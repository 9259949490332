<template>
  <div>
    <div class="contentBox">
      <div class="leftBox">
        <div class="leftTop">
          <img class="userImg" src="../../assets/head.png" alt="" />
          <div class="userName">{{ user.nickname }}</div>
          <div class="userId">ID：{{ user.id }}</div>
          <img
            v-if="vipInfo && vipInfo.isVip == 1"
            class="vipImg"
            src="@/assets/user/06_VIP_80px_40px_60px_30px.png"
            alt=""
          />
          <p v-if="vipInfo && vipInfo.isVip == 0">普通用户</p>
        </div>
        <div class="leftBottom">
          <div
            class="leftBottomBtn"
            :class="{ btnTrue: btnNum == 1 }"
            @click="btnFun(1)"
          >
            <img src="@/assets/user/07_bill_42px_42px_01.png" alt="" />
            <span>查看订单</span>
          </div>
          <div
            class="leftBottomBtn"
            :class="{ btnTrue: btnNum == 3 }"
            @click="btnFun(3)"
          >
            <img src="@/assets/user/09_my_42px_42px_00.png" alt="" />
            <span>个人资料</span>
          </div>
        </div>
      </div>
      <div class="rightBox">
        <div class="rightTop">
          <div class="rightTopLeft">
            <span>{{
              vipInfo && vipInfo.isVip == 1 ? '您已购买' : '您还不是VIP用户'
            }}</span>
            <img
              v-if="vipInfo && vipInfo.isVip == 1"
              src="@/assets/user/06_VIP_80px_40px_60px_30px.png"
              alt=""
            />
          </div>
          <div class="rightTopRight">
            <span
              v-if="vipInfo != null && vipInfo.expirationTime"
              style="margin-right: 30px"
              >使用有效期至：<span style="color: #c22424">{{
                vipInfo.expirationTime
              }}</span></span
            >
            <div class="rightTopBtn" v-if="vipInfo && vipInfo.isVip == 1">
              <span @click="vipFun()">立即续费</span>
            </div>
            <div class="rightTopBtn" v-if="vipInfo && vipInfo.isVip == 0">
              <span @click="vipFun()">立即购买</span>
            </div>
          </div>
        </div>
        <div class="rightBottom" v-show="btnNum == 1">
          <div class="rightTitle">我的订单</div>
          <div style="height: 348px">
            <el-table
              :data="tableData"
              :header-cell-style="{ 'text-align': 'center' }"
              :cell-style="{ 'text-align': 'center', height: '60px' }"
              stripe
              style="width: 100%"
            >
              <el-table-column prop="id" label="订单号" width="240">
              </el-table-column>
              <el-table-column prop="goodsName" label="商品名称" width="120">
              </el-table-column>
              <el-table-column prop="amount" label="订单金额" width="120">
                <template slot-scope="scope">{{
                  scope.row.amount | amountFil
                }}</template>
              </el-table-column>
              <el-table-column prop="buyTime" label="交易时间" width="200">
              </el-table-column>
              <el-table-column prop="status" label="交易状态" width="100">
                <template slot-scope="scope">{{
                  scope.row.status | statusFil
                }}</template>
              </el-table-column>
            </el-table>
          </div>
          <el-pagination
            style="text-align: center; padding: 20px 0"
            @current-change="handleCurrentChange"
            :page-size="5"
            layout="prev, pager, next"
            :total="total"
          >
          </el-pagination>
          <div class="fpBox">
            <div class="fpTitle">发票须知</div>
            <div class="fpContent">
              <span style="color: #c22424; font-weight: 700">开票时间：</span>
              无特殊或不可抗力情况下，我们将在
              <span style="color: #c22424">7个工作日内</span>
              为您开票，发票开出后可在您填写的接收邮箱中查看、下载。
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import dayjs from 'dayjs'
import { orderList, login } from '@/api/request'
import config from '@/config'

const loginUrl = config[process.env.NODE_ENV]['loginUrl']
const payUrl = config[process.env.NODE_ENV].payUrl
const passportUrl = config[process.env.NODE_ENV].passportUrl

export default {
  props: {},
  components: {},
  data() {
    return {
      tableData: [],
      user: {},
      vipInfo: {},
      page: 1,
      total: 0,
      btnNum: 1,
      timer: null,
    }
  },
  computed: {},
  filters: {
    amountFil(v) {
      return '￥' + String(v)
    },
    statusFil(v) {
      switch (Number(v)) {
        case 0:
          return '待支付'
          break
        case 1:
          return '已支付'
          break
        case 2:
          return '支付失败'
          break
        default:
          return '状态错误'
          break
      }
    },
  },
  created() {
    // if (localStorage.getItem('louser')) {
    //   this.user = JSON.parse(localStorage.getItem('louser'))
    // }
    let that = this
    that.timer = null
    that.timer = setInterval(function() {
      that.getUserInfo()
    }, 1000)

    // this.vipInfo = this.$store.state.userInfo.vipInfo
    // this.user = this.$store.state.userInfo.userInfo
    that.orderList()
  },
  beforeRouteLeave(to, from, next) {
    clearTimeout(this.timer)
    next() // 继续导航
  },
  mounted() {},
  watch: {},
  methods: {
    getUserInfo() {
      //获取登录信息
      login().then((res) => {
        //console.log(res)
        if (res.status == 200) {
          if (res.data.code == 401) {
            let thatUrl = location.href
            let url = encodeURIComponent(thatUrl)
            window.location.href = loginUrl + '' + url
          } else if (res.data.status == 0) {
            this.userInfo = res.data.data.userInfo
            this.vipInfo = res.data.data.vipInfo
            let vuexData = res.data.data
            this.$store.commit('addUserInfo', vuexData)
          } else {
            console.log('gg')
          }
        } else {
          console.log('服务器gg', res)
          this.loading = false
        }
      })
    },
    vipFun() {
      window.open(payUrl + '?appCode=' + 'gw')
    },
    btnFun(v) {
      switch (v) {
        case 3:
          let url = passportUrl + 'ucenter/index'
          window.open(url, '_blank')
          break
        default:
          this.btnNum = v
          break
      }
    },

    handleCurrentChange(v) {
      this.page = v
      this.orderList()
    },
    orderList() {
      let data = {
        page: this.page,
        limit: 5,
        appCode: 'gw',
      }
      orderList(data)
        .then((res) => {
          console.log(res)
          this.tableData = res.data.rows
          this.total = res.data.total
        })
        .catch((err) => {})
    },
  },
}
</script>

<style lang="scss" scoped>
.contentBox {
  width: 100%;
  min-height: 810px;
  padding-top: 25px;
  padding-bottom: 50px;
  .leftBox {
    width: 340px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: top;
    // height: 400px;
    // box-sizing: border-box;
    // background: #FFFFFF;
    // box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.2);
    // border-top: 4px solid #1571e5;
    .leftTop {
      width: 340px;
      height: 400px;
      margin-bottom: 10px;
      box-sizing: border-box;
      background: #ffffff;
      box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.2);
      border-top: 4px solid #1571e5;

      .userImg {
        width: 120px;
        height: 120px;
        margin: 80px 110px 40px;
      }
      .userName {
        font-family: Source Han Sans CN;
        font-size: 20px;
        font-weight: normal;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0em;
        color: #3d3d3d;
        text-align: content;
      }
      .userId {
        font-family: Source Han Sans CN;
        font-size: 16px;
        font-weight: normal;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0em;
        color: #888888;
        text-align: content;
        margin-top: 20px;
        margin-bottom: 20px;
      }
      .vipImg {
        width: 80px;
        height: 40px;
        margin: 0 130px;
      }
      p {
        margin: 0 135px;
      }
    }
    .leftBottom {
      width: 340px;
      height: 400px;
      box-sizing: border-box;
      padding: 36px 20px 40px;
      border-top: 4px solid #1571e5;
      background: #ffffff;
      box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.2);
      .leftBottomBtn {
        display: flex;
        width: 100%;
        height: 80px;
        box-sizing: border-box;
        cursor: pointer;
        padding: 16px 40px;
        // background: #EEF8FF;
        background: #f8f8f8;
        margin-bottom: 30px;
        img {
          width: 48px;
          height: 48px;
        }
        span {
          margin-left: 20px;
          font-family: Source Han Sans CN;
          font-size: 22px;
          font-weight: normal;
          line-height: 48px;
          letter-spacing: 0em;
          // color: #1571E5;
          color: #000000;
          display: inline-block;
          vertical-align: top;
        }
      }
      .btnTrue {
        background: #eef8ff;
        span {
          color: #1571e5;
          font-weight: bold;
        }
      }
    }
  }
  .rightBox {
    width: calc(100% - 350px);
    display: inline-block;
    vertical-align: top;
    .rightTop {
      width: 100%;
      height: 100px;
      box-sizing: border-box;
      border-top: 4px solid #1571e5;
      background: #ffffff;
      box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.2);
      margin-bottom: 10px;
      padding: 35px 20px 35px 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .rightTopLeft {
        line-height: 30px;
        font-size: 18px;
        display: inline-block;
        display: flex;
        span {
          display: inline-block;
          vertical-align: top;
          margin-right: 13px;
        }
        img {
          width: 60px;
          height: 30px;
        }
      }
      .rightTopRight {
        line-height: 30px;
        font-size: 18px;
        display: inline-block;
        vertical-align: top;
        float: right;
        span {
          display: inline-block;
          vertical-align: top;
        }
        .rightTopBtn {
          cursor: pointer;
          vertical-align: top;
          display: inline-block;
          width: 100px;
          height: 30px;
          border-radius: 4px;
          opacity: 1;
          background: #1571e5;
          text-align: center;
          span {
            line-height: 30px;
            font-family: Source Han Sans CN;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0.1em;
            color: #ffffff;
          }
        }
      }
    }
    .rightBottom {
      width: 100%;
      // height: 700px;
      box-sizing: border-box;
      border-top: 4px solid #1571e5;
      background: #ffffff;
      box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.2);
      padding: 30px 20px 45px 30px;
      .rightTitle {
        padding-bottom: 30px;
        font-family: Source Han Sans CN;
        font-size: 24px;
        font-weight: bold;
        line-height: 24px;
        letter-spacing: 0em;
        color: #105aa5;
      }
      .fpBox {
        width: 100%;
        height: 130px;
        background-color: #f8f8f8;
        box-sizing: border-box;
        padding: 20px;
        margin-top: 21px;
        .fpTitle {
          font-family: Source Han Sans CN;
          font-size: 18px;
          font-weight: bold;
          line-height: 24px;
          letter-spacing: 0em;
          color: #1571e5;
        }
        .fpContent {
          margin-top: 15px;
          font-family: Source Han Sans CN;
          font-size: 16px;
          font-weight: normal;
          line-height: 24px;
          letter-spacing: 0em;
        }
      }
      .rightTypeBox {
        width: 100%;
        .typeTitle {
          width: 100%;
          height: 60px;
          background: #eef8ff;
          box-sizing: border-box;
          padding: 15px 40px 15px 20px;
          span {
            display: inline-block;
          }
          .title {
            font-family: Source Han Sans CN;
            font-size: 20px;
            font-weight: bold;
            line-height: 30px;
            letter-spacing: 1em;
            color: #c22424;
          }
          .typeIcon {
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 2px;
            background: #1571e5;
            text-align: center;
            line-height: 20px;
            color: #ffffff;
          }
          .allBtn {
            width: 60px;
            height: 30px;
            border-radius: 4px;
            opacity: 1;
            background: #1571e5;
            margin-right: 30px;
            text-align: center;
            font-family: Source Han Sans CN;
            font-size: 16px;
            font-weight: normal;
            line-height: 30px;
            letter-spacing: 0.2em;
            color: #ffffff;
            cursor: pointer;
            display: inline-block;
          }
          .delBtn {
            width: 60px;
            height: 30px;
            border-radius: 4px;
            opacity: 1;
            background: #c22424;
            text-align: center;
            font-family: Source Han Sans CN;
            font-size: 16px;
            font-weight: normal;
            line-height: 30px;
            letter-spacing: 0.2em;
            color: #ffffff;
            cursor: pointer;
            display: inline-block;
          }
        }
      }
    }
  }
}

// 个人资料
.userContentBox {
  width: 100%;
  height: 780px;
  border-top: 4px solid #0f59a4;
  box-sizing: border-box;
  text-align: center;
  background-color: #fff;
  margin-top: 20px;
  .userImg {
    width: 120px;
    height: 120px;
    margin: 80px auto 30px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  .imgBtn {
    width: 100px;
    height: 40px;
    margin: 0 auto;
    background-color: #1571e5;
    font-family: Source Han Sans CN;
    font-size: 16px;
    font-weight: normal;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0em;
    color: #ffffff;
    cursor: pointer;
    margin-bottom: 80px;
  }
}
</style>
